<template>
  <ul class="menu-nav">
    <!-- OVERVIEW -->
    <li class="menu-section">
      <h4 class="menu-text">{{ $t("MENU.OVERVIEW") }}</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <!-- DASHBOARD ENERGY CONSUMPTION -->
    <router-link
      to="/dashboard/energy/consumption"
      v-if="hasElectricRights"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa-solid fa-bolt"></i>
          <span class="menu-text">{{ $t("MENU.DASHBOARD_CONSUMPTION") }}</span>
        </a>
      </li>
    </router-link>

    <!-- DASHBOARD ENERGY PRODUCTION -->
    <router-link
      to="/dashboard/energy/production"
      v-if="hasSolarRights"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa-solid fa-solar-panel"></i>
          <span class="menu-text">{{ $t("MENU.DASHBOARD_PRODUCTION") }}</span>
        </a>
      </li>
    </router-link>

    <!-- DASHBOARD GAS -->
    <router-link
      to="/dashboard/gas"
      v-if="hasGasRights"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa-solid fa-fire-flame-simple"></i>
          <span class="menu-text">{{ $t("MENU.DASHBOARD_GAS") }}</span>
        </a>
      </li>
    </router-link>

    <!-- ENERGY MANAGEMENT -->
    <li class="menu-section">
      <h4 class="menu-text">{{ $t("MENU.ENERGY_MANAGEMENT") }}</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <!-- CONSUMPTION ENERGY -->
    <router-link
      to="/consumption/energy"
      v-if="hasElectricRights"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa-solid fa-bolt"></i>
          <span class="menu-text">{{ $t("MENU.ENERGY_CONSUMPTION") }}</span>
        </a>
      </li>
    </router-link>

    <!-- GENERATION -->
    <router-link
      to="/generation"
      v-if="hasSolarRights"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa-solid fa-solar-panel"></i>
          <span class="menu-text">{{ $t("MENU.ENERGY_GENERATION") }}</span>
        </a>
      </li>
    </router-link>

    <!-- CONSUMPTION GAS -->
    <router-link
      to="/consumption/gas"
      v-if="hasGasRights"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa-solid fa-fire-flame-simple"></i>
          <span class="menu-text">{{ $t("MENU.GAS_CONSUMPTION") }}</span>
        </a>
      </li>
    </router-link>

    <!-- CLIENT MANAGEMENT -->
    <li class="menu-section">
      <h4 class="menu-text">{{ $t("MENU.CLIENT_MANAGEMENT") }}</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <!-- CLIENTS -->
    <router-link
      to="/clients"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa-solid fa-users"></i>
          <span class="menu-text">{{ $t("MENU.CLIENTS") }}</span>
        </a>
      </li>
    </router-link>

    <!-- ONBOARD -->
    <router-link
      to="/onboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa-solid fa-user-check"></i>
          <span class="menu-text">{{ $t("MENU.ONBOARD") }}</span>
        </a>
      </li>
    </router-link>

    <!-- MANAGE EVENTS -->
    <router-link
      to="/manage-events"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa-solid fa-calendar-days"></i>
          <span class="menu-text">{{ $t("MENU.MANAGE_EVENTS") }}</span>
        </a>
      </li>
    </router-link>

    <!-- ADMINISTRATION -->
    <li class="menu-section">
      <h4 class="menu-text">{{ $t("MENU.ADMINISTRATION") }}</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <!-- IMPORT -->
    <!-- ENERGY -->
    <router-link
      to="/import-energy"
      v-if="hasElectricRights"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa-solid fa-bolt"></i>
          <span class="menu-text">{{ $t("MENU.IMPORT_ENERGY") }}</span>
        </a>
      </li>
    </router-link>
    <!-- GAS -->
    <router-link
      to="/import-gas"
      v-if="hasGasRights"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa-solid fa-fire-flame-simple"></i>
          <span class="menu-text">{{ $t("MENU.IMPORT_GAS") }}</span>
        </a>
      </li>
    </router-link>
    <!-- ADMINISTRATION -->
    <li class="menu-section">
      <h4 class="menu-text">{{ $t("MENU.PERFORMANCE") }}</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <!-- PERFORMANCE ENERGY -->
    <router-link
      to="/performance-electric"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa-solid fa-bolt"></i>
          <span class="menu-text">{{ $t("MENU.PERFORMANCE_ENERGY") }}</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/performance-solar"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa-solid fa-solar-panel"></i>
          <span class="menu-text">{{ $t("MENU.PERFORMANCE_SOLAR") }}</span>
        </a>
      </li>
    </router-link>
    <!-- PERFORMANCE GAS -->
    <router-link
      to="/performance-gas"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa-solid fa-fire-flame-simple"></i>
          <span class="menu-text">{{ $t("MENU.PERFORMANCE_GAS") }}</span>
        </a>
      </li>
    </router-link>
    <!-- ADMINISTRATION -->
    <li class="menu-section">
      <h4 class="menu-text">{{ $t("MENU.USER_MANAGEMENT") }}</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <router-link
      v-if="hasAdminRights"
      to="/administration"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa-solid fa-users"></i>
          <span class="menu-text">{{ $t("MENU.USERS") }}</span>
        </a>
      </li>
    </router-link>

    <!-- MY PROFILE -->
    <router-link
      to="/profile"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fa-solid fa-circle-user"></i>
          <span class="menu-text">{{ $t("MENU.PROFILE") }}</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import ApiService from "@/core/services/api.service";
import UsernameService from "@/core/services/username.service";

export default {
  name: "KTMenu",
  data() {
    return {
      hasAdminRights: false,
      hasElectricRights: false,
      hasSolarRights: false,
      hasGasRights: false,
    };
  },
  created() {
    this.hasRightToSeeAdministrationPanel();
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    hasRightToSeeAdministrationPanel() {
      var _this = this;
      ApiService.post("/is-admin/", { username: UsernameService.getUsername() })
        .then((response) => {
          _this.hasAdminRights = response.data.value;
          localStorage.setItem("permissions", response.data.permissions);
          _this.hasElectricRights =
            response.data.permissions.indexOf("electric") != -1;
          _this.hasSolarRights =
            response.data.permissions.indexOf("solar") != -1;
          _this.hasGasRights = response.data.permissions.indexOf("gas") != -1;
        })
        .catch(() => {
          //do something... after toastr...
          _this.hasAdminRights = false;
        });
    },
  },
};
</script>
